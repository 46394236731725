import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import {MdCircle} from "react-icons/md";
import Profile from "./Profile";

export function ResumeTabs() {

  const vify = (
      <div className="text-gray-400">
        <div className="flex justify-between mt-3">
          <p className="font-bold ">FullStack Software Engineer</p>
          <p className="font-bold text-green-700">01/2023 - 12/2023</p>
        </div>
        <p className="text-green-700">Vify Technology Company, Hanoi VietNam</p>

        <p className="">Vify : Built Shopify apps which are used by Shopify&apos;s merchants. These apps help these
          merchants to organize their business more successfully.</p>
        <p className='underline'>What I did :</p>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Participated in important projects of the company.</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Maintained current apps and support customers from all over the world.</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Fixed bugs and add new features to the products.</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Trained freshers.</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Supported my co-workers with difficult technical issues.</p>
        </div>
        <p className='underline'>Highlights:</p>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Contributed 50% of front-end codes and built the set-up codes of frontend part.</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Created one complete new feature : Product update and creator, which is one important
            feature.</p>
        </div>
        <p className='underline'>Technology used:</p>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">NestJS, Typescripts, MongoDB, AWS, React, MySQL.</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">GitLab, Jira.</p>
        </div>
      </div>
  )

  const cleaner = (
      <>
        <div className="flex justify-between mt-3">
          <p className="font-bold ">Cleaner at My Absolute Domestics</p>
          <p className="font-bold text-green-700">09/2024 - Present</p>
        </div>
        <p className="text-green-700">Adelaide</p>
        <div className="flex items-center">
          <p className="underline">What I did :</p>
        </div>
        <div className="flex items-center">
          <p className="ml-2">Go to clients&apos; homes to: </p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Dust all rooms </p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Vacuum carpets and floors </p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Clean toilets and bathrooms</p>
        </div>
        <p className='underline'>Highlights:</p>
        <div className="flex flex-col">
          <div className="flex items-center">
            <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
            <p className="ml-2">I am working through an app which I have to self-organize my time. This has demonstrated
              my good customer service</p>
          </div>
          <div className="flex items-center">
            <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
            <p className="ml-2">I have a great opportunity to show have a conversation with my client and understand the
              culture deeper</p>
          </div>
        </div>
      </>
  )

  const uberDriver = (
      <>
        <div className="flex justify-between mt-3">
          <p className="font-bold ">Uber eat Driver</p>
          <p className="font-bold text-green-700">03/2024 - 09/2024</p>
        </div>
        <p className="text-green-700">Adelaide</p>
        <div className="flex items-center">
          <p className="underline">What I did :</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Got foods from restaurants and delivered to customers</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Had conversations with waiters, waitresses, and customers </p>
          <p className="ml-2">Tried to give them a delight day </p>
        </div>
        <p className='underline'>Highlights:</p>
        <div className="flex flex-col">
          <div className="flex items-center">
            <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
            <p className="ml-2">Even though it is a very simple job, I took it seriously and really enjoyed doing
              it.</p>
          </div>
          <div className="flex items-center">
            <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
            <p className="ml-2">Thus, I received satisfying feedback from 97% of my customers</p>
          </div>
          <div className="flex items-center">
            <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
            <p className="ml-2">I delivered thousands of trips</p>
          </div>
        </div>
      </>
  )

  const costaFarm = (
      <>
        <div className="flex justify-between mt-3">
          <p className="font-bold ">Grower</p>
          <p className="font-bold text-green-700">02/2024 - 03/2024</p>
        </div>
        <p className="text-green-700">Costa Mushroom - Monarto South SA</p>
        <div className="flex items-center">
          <p className="underline">What I did :</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Water mushrooms regularly</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2"> Sprayed chemicals to protect mushrooms from diseases</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Inspected mushrooms to report to supervisors in order to take actions to help them grow
            better
          </p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">cooperated with harvesters to take care of mushrooms
          </p>
        </div>
        <p className='underline'>Highlights:</p>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Quickly played an major role in the team after 1 week of probation</p>
        </div>
      </>
  )

  const CSharpFreelancer = (
      <>
        <div className="flex justify-between mt-3">
          <p className="font-bold ">Freelancer</p>
          <p className="font-bold text-green-700">06/2023 - Present</p>
        </div>
        <p className="text-green-700"><a href="https://mywebbie.com.au" className="text-customGreen hover:underline"
                                         target="_blank"
                                         rel="noopener noreferrer">My Webbie (mywebbie.com.au)</a></p>

        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Second project with C# .NET : Invoice Generator</p>
        </div>
        <div className="flex items-center">
          <p className="underline">What I did :</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Set up backend using C# .NET 8 and clean architecture</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2"> Set up frontend using React</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Set up AWS Server</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Set up Database using PostgreSQL</p>
        </div>
        <p className='underline'>Highlights:</p>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Created responsive web application</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Set up C# backend with Authentication, Validation and Well-structured database</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Handled Exceptions in an well-organized way</p>
        </div>
        <p className='underline'>Technology used:</p>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">.NET 8, React, PostgreSQL, AWS</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">GitLab</p>
        </div>

        <a href="https://quickinvoicegenerator.com" className="text-customGreen" target="_blank"
           rel="noopener noreferrer">https://quickinvoicegenerator.com</a>
      </>
  )

  const freelancer = (
      <>
        <div className="flex justify-between mt-3">
          <p className="font-bold ">Freelancer</p>
          <p className="font-bold text-green-700">06/2023 - Present</p>
        </div>
        <p className="text-green-700"><a href="https://mywebbie.com.au" className="text-customGreen hover:underline"
                                         target="_blank"
                                         rel="noopener noreferrer">My Webbie (mywebbie.com.au)</a></p>

        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Created Shopify Application: Request a Quote + Hide price</p>
        </div>
        <div className="flex items-center">
          <p className="underline">What I did :</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Chose a type of product to promote</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2"> Set up backend using NestJS and frontend using React Set up AWS Server</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Set up Database using both MongoDB and MySQL</p>
        </div>
        <p className='underline'>Highlights:</p>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Finished the 90% of the product just within 6 months</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Built Oauth2.0 process</p>
        </div>
        <p className='underline'>Technology used:</p>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">NestJS, Typescripts, MongoDB, AWS, React, MySQL</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">GitLab</p>
        </div>
      </>
  )

  const bstarTeacher = (
      <>
        <div className="flex justify-between mt-3">
          <p className="font-bold ">Online English Teacher</p>
          <p className="font-bold text-green-700">02/2021 - Now</p>
        </div>
        <p className="text-green-700">B-Star English Center, Hanoi VietNam</p>
        <p className='underline'>What I did :</p>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Taught English online to primary, secondary and high school students</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Created my own teaching materials</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Promoted my teaching to students and parents from all over the country by using social
            media</p>
        </div>
        <div className="flex items-center">
          <p className="underline">Highlights: </p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">I had students from many provinces and cities in the country
            This job has helped me to improve my teaching skills, which are essential to build up my teamwork
            skills.</p>
        </div>
      </>
  )

  const amesManager = (
      <>
        <div className="flex justify-between mt-3">
          <p className="font-bold ">Deputy Director</p>
          <p className="font-bold text-green-700">03/2017 - 02/2019</p>
        </div>
        <p className="text-green-700">Ames English, Thanh Hoa</p>

        <p className='underline'>What I did :</p>

        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Managed and operated all activities of the center , including finance, human resources,
            teaching plans, etc.</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Managed sales and marketing activities.</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Built up successfully the teaching material system which was suitable for most
            students</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Established a good relationship with other local organizations and groups.</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Trained the teachers and other employees regularly</p>
        </div>

        <p className="underline">Highlights: </p>

        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">In 2018, the center achieved 90% of its annual sales target and 100%
            students had got the expected results</p>
        </div>
        <p className="mt-2">This job helped me to build up my leadership skills , management skills, and especially my
          problem solving skills which are extremely essential for my software engineering career.</p>
      </>
  )

  const neverland = (
      <>
        <div className="flex justify-between mt-3">
          <p className="font-bold ">Deputy Director</p>
          <p className="font-bold text-green-700">03/2012 - 11/2013</p>
        </div>
        <p className="text-green-700">Viet An Trading and Services Limited Company, Thanh Hoa</p>
        <p>Launched many marketing campaigns to improve the restaurant’s
          image.</p>

        <p className="underline">What I did: </p>

        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Boosted sales and increased profits:</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Kept in touch with customers</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Used many sales strategies, for example :implementing a referral program, using future
            discounts, rewards points, special access, and freebies, etc.</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Trained staff members and built up company culture : Get the
            employees trained weekly and regularly set up many connection
            activities to bring people close together.</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Manage finance and deal with suppliers.</p>
        </div>

        <p className="underline">Highlights: </p>

        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="mt-2">The monthly revenues increased from 450 million VNĐ to 550
            million VNĐ, especially in February 2013 that figure even rose to 850
            million VNĐ.</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="mt-2"> successfully built a pretty independent system that could work
            acceptably well without my management, especially during the
            holidays and festivals.</p>
        </div>
      </>
  )

  const legiaComputer = (
      <>
        <div className="flex justify-between mt-3">
          <p className="font-bold ">Deputy Manager and Co-Founder</p>
          <p className="font-bold text-green-700">06/2009 - 01/2012</p>
        </div>
        <p className="text-green-700">LeGia Computer Limited Company, Ha Noi</p>
        <p>Le Gia is a company which supports customers with software services and sells desktop Computers , Laptops and
          accessories.</p>

        <p className="underline">What I did: </p>

        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2"> Established the company:</p>
        </div>

        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">- Did research and make the business plans.</p>
        </div>

        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">- Trained employees about Operating systems and the Internet </p>
        </div>

        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">- Made sales and marketing plans</p>
        </div>

        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Managed and operated the company : </p>
        </div>

        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">- motivated employees </p>
        </div>

        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">- trained employees regularly</p>
        </div>

        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">- built company culture.</p>
        </div>

        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2"> Built good relationship with other business partners: Joined some football group to make
            the company well-known</p>
        </div>

        <p className="underline">Highlights: </p>

        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2"> Took very high responsibility for the company’s growth. Set up
            successfully the company that works very well.</p>
        </div>
      </>
  )

  const cmcDistributor = (
      <>
        <div className="flex justify-between mt-3">
          <p className="font-bold ">Product Consultant & Sales executive </p>
          <p className="font-bold text-green-700">10/2010 -01/2012</p>
        </div>
        <p className="text-green-700">CMC Distribution Limited Company, CMC Tower, Duy Tan, Cau Giay, Ha Noi </p>

        <p className="underline">What I did: </p>

        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Joined many training sessions about Laptop and its components and performance.</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Learned deeply about operating systems and theirs effects on a laptop’s performance.</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Supported my co-workers with products’ consulting , and met their customers to talk about
            products’ features.</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Gained market share and increased sales: By deeply understanding of products and having
            close-knit relationships with Ha Noi’s IT dealers ( such as Tran Anh, Phuc Anh, Pico, Topcare, Mediamart,
            Ben,etc.) , I could create a very stable sales channel. </p>
        </div>

        <p className="underline">Highlights: </p>

        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Became a trusted product consultant and reached sales targets for two
            quarters, especially I have achieved the highest quarterly sale target among all sales executives in the
            company.</p>
        </div>
      </>
  )

  const visionSalesExecutive = (
      <>
        <div className="flex justify-between mt-3">
          <p className="font-bold ">Sales executive</p>
          <p className="font-bold text-green-700">08/2009 -09/2010</p>
        </div>
        <p className="text-green-700">Vision Joint Stock Company, Ha Noi </p>

        <p className="underline">Highlights: </p>

        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Joined many training sessions about Network accessories like routers , switch</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Learned deeply about Network and the Internet.</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Supported my co-workers with products’ consulting , and met their customers to talk about
            products’ features.</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Gained market share: Through widening the relationships with
            directors and staffs of Hanoi’s IT companies trading computer
            equipments.</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Increased sales: By persuading customers to buy my products
            regularly in large quantities, I contributed to my company&apos;s success in
            increasing revenues.</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Developed products: By means of taking part frequently in the
            product conferences for IT dealers, I helped my company to promote
            its products.</p>
        </div>

        <p className="underline">Highlights: </p>
        <div className="flex flex-col">
          <div className="flex items-center">
            <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
            <p className="ml-2">1.Had 3 new customers every month </p>
          </div>
          <div className="flex items-center">
            <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
            <p className="ml-2">2.Reached sales target for 3 months, particularly reached 150% of the sales target of 1.5 Million VNĐ for 1 month.</p>
          </div>
        </div>
      </>
  )

  const tiensSalesExecutive = (
      <>
        <div className="flex justify-between mt-3">
          <p className="font-bold ">Sales Executive & Manager & Occupational health and counsellor</p>
          <p className="font-bold text-green-700">12/2007 -07/2009</p>
        </div>
        <p className="text-green-700">TIENS Viet Nam Corporation, Ha Noi </p>

        <p className="underline">What I did: </p>

        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Got in touch, looked for new customers, assisted in marketing and
            PR work.</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Built my own multi level marketing network.</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Recruited Promotion Girls for promotion programs.</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Pulled together, motivated my distributors.</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Organized the company events such as meetings, seminars, training
            programs, exhibitions, performances, new products launches....</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Made lecture slides and promotional materials for the company</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Developed products in some domestic provinces such as Quang Ninh,
            Thanh Hoa, Nghe An, Hai Duong …</p>
        </div>
        <div className="flex items-center">
          <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
          <p className="ml-2">Brought my distributors to Beijing China in order to get trained, in
            January 2009, and Nanning in April 2009.</p>
        </div>

        <p className="underline">Highlights: </p>

        <div className="flex flex-col">
          <div className="flex items-center">
            <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
            <p className="ml-2">1. Had achieved many awards for the excellent sales executives.</p>
          </div>
          <div className="flex items-center">
            <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
            <p className="ml-2">2. Had achieved the annual sales target of 65000$.</p>
          </div>
          <div className="flex items-center">
            <MdCircle className="h-[12px] w-[12px] text-blue-gray-500 "/>
            <p className="ml-2">3. Had become a shareholder of Banner joint-stock company which<br/>
              is a subsidiary company of Tiens Corporation.</p>
          </div>
        </div>

      </>
  )

  const profile = (
      <>
        <p className="text-xl mb-2 text-customGreen">Profile</p>
        <hr/>
        <Profile/>
      </>
  )
  const education = (
      <div className="text-gray-400">
        <p className="text-xl mt-2 mb-2 text-customGreen">Education</p>
        <hr/>
        <div className="flex justify-between mt-2">
          <p className="font-bold">Computer Science</p>
          <p className="font-bold text-green-700">09/2004 - 09/2012</p>
        </div>
        <p className="text-green-700">Hanoi University of Technology, Hanoi VietNam</p>
        <p>
          I have graduated with a good bachelor&apos;s degree. During my studies, I participated to many extracurricular
          activities which helped me to improve my academic performance, and built up my social skills that have been
          used a lot at the workplace in my future jobs.
        </p>
        <div className="flex justify-between">
          <p className="font-bold">Business Administration</p>
          <p className="font-bold text-green-700">09/2013 - 09/2014</p>
        </div>
        <p className="text-green-700">Hanoi Open University, Hanoi VietNam</p>
        <p>
          In order to be more flexible at the workplace, I decided to take this course to master my business skills. I
          have not only learned a lot within the study year, but also had a chance to make new business-preferred
          friends who have a lot of experience in the business world.
        </p>
      </div>
  )

  const itEmployment = (
      <div className="text-gray-400">
        <p className="text-xl mt-3 mb-2 text-customGreen">Employment</p>
        <hr/>
        {CSharpFreelancer}
        {freelancer}
        {vify}

      </div>
  )

  const businessEmployment = (
      <div className="text-gray-400">
        <p className="text-xl mt-3 mb-2 text-customGreen">Employment</p>
        <hr/>
        {cleaner}
        {uberDriver}
        {costaFarm}
        {bstarTeacher}
        {amesManager}
        {neverland}
        {legiaComputer}
        {cmcDistributor}
        {visionSalesExecutive}
        {tiensSalesExecutive}
      </div>
  )
  const data = [
    {
      label: "Profile",
      value: "profile",
      desc: profile
    },
    {
      label: "ITExperience",
      value: "itExperience",
      desc: itEmployment
    },
    {
      label: "OtherExperience",
      value: "businessExperience",
      desc: businessEmployment
    },
    {
      label: "Education",
      value: "education",
      desc: education
    },
  ];

  return (
      <Tabs value="profile">
        <TabsHeader
            className="bg-blue-gray-800 bg-opacity-60"
        >
          {data.map(({label, value}) => (
              <Tab className="text-white"
                   key={value}
                   value={value}>
                {label}
              </Tab>
          ))}
        </TabsHeader>
        <TabsBody>
          {data.map(({value, desc}) => (
              <TabPanel key={value} value={value}>
                {desc}
              </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
  );
}
